import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";
import {Lender} from "../../../../Constants/Lender";

const BasicQuestionnaireTwo = (props) => {

  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [checkedQuestionThree, setCheckedQuestionThree] = useState("unchecked");
  const [checkedQuestionFour, setCheckedQuestionFour] = useState("unchecked");
  const [checkedQuestionFive, setCheckedQuestionFive] = useState("unchecked");
  const [checkedQuestionSix, setCheckedQuestionSix] = useState("unchecked");
  const [checkedQuestionSeven, setCheckedQuestionSeven] = useState("unchecked");

  const nextSlide = async (e) => {
    var bankLabel= document.querySelector("[for='" + e.target.id + "']").textContent;
    localStorage.setItem('bankName',bankLabel);
    props.slideChange(e);
  };

  const LenderList = props.gtm_question.answers.map((value,index)=> {
    return (
      <RadioButton
        id={value.id}
        key={index}
        value={value.value}
        name={props.buttonName}
        className="banknames-radio bankks"
        labelName={value.label}
        labelClassName={`banknames-radio  checked ${checkedQuestionOne}`}
        onClick={nextSlide}
        validation={props.validation()}
        gtm_question ={props.gtm_question.gtmQuestion}
        gtm_trigger={props.gtm_question.gtm_trigger}
        gtm_answer={value.gtmAnswer}
        clickevent={props.gtm_question.clickevent}
      />
    )
  });


  return (
    <>

      <div id="slide-2" className={` slidetop_adjust ${props.className}`}>
      <ProgressBar value="15"/>
        <h4 className="text-center">
          {props.gtm_question.question}
        </h4>
        <div className="radio_buttons">
          {LenderList}
        </div>
        {/* <p className="text-center">
          <span className="back-btn" name="back01" id="back-1" onClick={previousSlide}>
            <u>{`<<`} Previous</u>
          </span>
        </p> */}
        <p className="text-center">
          <span
              className="back-btn"
              name="back01"
              children="<< Previous"
              onClick={props.backClick}
          ></span>
        </p>
      </div>
    </>
  );
};
export default BasicQuestionnaireTwo;
