import { useCallback, useContext } from "react";
import * as EnvConstants from "../Constants/EnvConstants";
import { CommonEmailValidateApi } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";
import VisitorParamsContext from "../Contexts/VisitorParams";

export const useEmailValidation = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { ErrorWebhookTrigger } = useErrorWebhook();

  const emailValidation = useCallback(async (email) => {
    const getValidation = await CommonEmailValidateApi.post(
      "api/validate?ProjectCode=" +
        EnvConstants.AppAdtopiaUPID +
        "&Environment=" +
        EnvConstants.AppEnv +
        "&Email=" +
        email,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: "EMAIL VALIDATION",
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitorParameters?.visitor_parameters?.uuid,
      };
      ErrorWebhookTrigger(erroMessage);
    });

    return getValidation;
  }, []);

  const validateEmail = useCallback(
    async (email) => {
      if (!email) return;

      const { data } = await emailValidation(email);
      if (!data) return;

      if (data?.status_code != 1) {
        return data?.message;
      }
    },
    [emailValidation]
  );

  return { emailValidation, validateEmail };
};
