import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import Split_1 from "./Components/Pages/Splits/Split_1";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Questionnaire from "./Components/Pages/Questionnaire";
import Preview from "./Components/Pages/Preview";
import OpenBankingProceed from "./Components/Pages/OpenBankingProceed";
import Unqualified from "./Components/Pages/Unqualified";
import Signature from "./Components/Pages/Signature";
// import Thankyou from "./Components/Pages/Thankyou";
import IdUpload from "./Components/Pages/IdUpload";
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact";
import BudsRedirectPage from "./Components/Pages/BudsRedirectPage";
import Proceed from "./Components/Pages/Proceed";
import PdfView from "./Components/Pages/PdfView";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";

// lazy imports --------------------------------------------------------------------
const SJP_V1_Claim = lazy(() => import("./Components/Pages/SJP_V1/Claim"));
const SJP_V1_Claim_V2 = lazy(() => import("./Components/Pages/SJP_V2/Claim"));

const SJP_V1_Claim_V2_1 = lazy(() =>
  import("./Components/Pages/SJP_V2_1/Claim")
);

const SJP_V1_Claim_V2_1_1 = lazy(() =>
  import("./Components/Pages/SJP_V2_1_1/Claim")
);

const SJP_V1_Claim_V2_1_1_Form = lazy(() =>
  import("./Components/Pages/SJP_V2_1_1/Form")
);

const SJP_V1_Claim_V2_2 = lazy(() =>
  import("./Components/Pages/SJP_V2_2/Claim")
);

const SJP_V1_Claim_Questionnaire = lazy(() =>
  import("./Components/Pages/SJP_V1/Questionnaire")
);

const SJP_V2_Claim_Questionnaire = lazy(() =>
  import("./Components/Pages/SJP_V2/Questionnaire")
);

const SJP_V2_1_Claim_Questionnaire = lazy(() =>
  import("./Components/Pages/SJP_V2_1/Questionnaire")
);

const SJP_V2_1_1_Claim_Questionnaire = lazy(() =>
  import("./Components/Pages/SJP_V2_1_1/Questionnaire")
);

const SJP_V2_2_Claim_Questionnaire = lazy(() =>
  import("./Components/Pages/SJP_V2_2/Questionnaire")
);

const AccountNumber = lazy(() =>
  import("./Components/Pages/SJP_V1/AccountNumber")
);

const AccountNumberV2 = lazy(() =>
  import("./Components/Pages/SJP_V2/AccountNumber")
);

const AccountNumberV2_1 = lazy(() =>
  import("./Components/Pages/SJP_V2_1/AccountNumber")
);

const AccountNumberV2_1_1 = lazy(() =>
  import("./Components/Pages/SJP_V2_1_1/AccountNumber")
);

const AccountNumberV2_2 = lazy(() =>
  import("./Components/Pages/SJP_V2_2/AccountNumber")
);

const Thankyou = lazy(() => import("./Components/Pages/SJP_V1/Thankyou"));
const Followup = lazy(() => import("./Components/Pages/Followup/Followup"));

const QuestionnaireFlp_SJP_V1 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V1/Questionnaire")
);
const AccountNumberFlp_SJP_V1 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V1/AccountNumber")
);

const QuestionnaireFlp_SJP_V2 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V2/Questionnaire")
);
const AccountNumberFlp_SJP_V2 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V2/AccountNumber")
);

const QuestionnaireFlp_SJP_V2_1 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V2_1/Questionnaire")
);
const AccountNumberFlp_SJP_V2_1 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V2_1/AccountNumber")
);

const AccountNumberFlp_SJP_V2_1_1 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V2_1_1/AccountNumber")
);

const QuestionnaireFlp_SJP_V2_2 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V2_2/Questionnaire")
);
const AccountNumberFlp_SJP_V2_2 = lazy(() =>
  import("./Components/Pages/Followup/SJP_V2_2/AccountNumber")
);

const SJP_ADV1 = lazy(() => import("./Components/Pages/Advertorials/SJP_ADV1"));

// -----------------------------------------------------------------------------------

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: "" });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <Suspense fallback="Loading...">
              <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route exact path={["/Split_1"]} component={Split_1} />
                <Route exact path={["/LP_SJP_V1"]} component={SJP_V1_Claim} />

                <Route
                  exact
                  path={["/advertorial/SJP_ADV1"]}
                  component={SJP_ADV1}
                />

                <Route
                  exact
                  path={["/LP_SJP_V2"]}
                  component={SJP_V1_Claim_V2}
                />

                <Route
                  exact
                  path={["/LP_SJP_V2_1"]}
                  component={SJP_V1_Claim_V2_1}
                />

                <Route
                  exact
                  path={["/LP_SJP_V2_1_1"]}
                  component={SJP_V1_Claim_V2_1_1}
                />

                <Route
                  exact
                  path={["/LP_SJP_V2_1_1_Form"]}
                  component={SJP_V1_Claim_V2_1_1_Form}
                />

                <Route
                  exact
                  path={["/LP_SJP_V2_2"]}
                  component={SJP_V1_Claim_V2_2}
                />

                <Route
                  exact
                  path={"/questionnaire_sjp_v1"}
                  component={SJP_V1_Claim_Questionnaire}
                />

                <Route
                  exact
                  path={"/questionnaire_sjp_v2"}
                  component={SJP_V2_Claim_Questionnaire}
                />

                <Route
                  exact
                  path={"/questionnaire_sjp_v2_1"}
                  component={SJP_V2_1_Claim_Questionnaire}
                />

                <Route
                  exact
                  path={"/questionnaire_sjp_v2_1_1"}
                  component={SJP_V2_1_1_Claim_Questionnaire}
                />

                <Route
                  exact
                  path={"/questionnaire_sjp_v2_2"}
                  component={SJP_V2_2_Claim_Questionnaire}
                />

                <Route
                  exact
                  path={["/account-number"]}
                  component={AccountNumber}
                />

                <Route
                  exact
                  path={["/account-number_v2"]}
                  component={AccountNumberV2}
                />

                <Route
                  exact
                  path={["/account-number_v2_1"]}
                  component={AccountNumberV2_1}
                />

                <Route
                  exact
                  path={["/account-number_v2_1_1"]}
                  component={AccountNumberV2_1_1}
                />

                <Route
                  exact
                  path={["/account-number_v2_2"]}
                  component={AccountNumberV2_2}
                />

                <Route exact path={"/followup"} component={Followup} />
                <Route
                  exact
                  path={"/followup/questionnaire_sjp_v1"}
                  component={QuestionnaireFlp_SJP_V1}
                />

                <Route
                  exact
                  path={"/followup/questionnaire_sjp_v2"}
                  component={QuestionnaireFlp_SJP_V2}
                />

                <Route
                  exact
                  path={"/followup/questionnaire_sjp_v2_1"}
                  component={QuestionnaireFlp_SJP_V2_1}
                />

                <Route
                  exact
                  path={"/followup/questionnaire_sjp_v2_2"}
                  component={QuestionnaireFlp_SJP_V2_2}
                />

                <Route
                  exact
                  path={"/followup/account-number"}
                  component={AccountNumberFlp_SJP_V1}
                />

                <Route
                  exact
                  path={"/followup/account-number_v2"}
                  component={AccountNumberFlp_SJP_V2}
                />

                <Route
                  exact
                  path={"/followup/account-number_v2_1"}
                  component={AccountNumberFlp_SJP_V2_1}
                />

                <Route
                  exact
                  path={"/followup/account-number_v2_1_1"}
                  component={AccountNumberFlp_SJP_V2_1_1}
                />

                <Route
                  exact
                  path={"/followup/account-number_v2_2"}
                  component={AccountNumberFlp_SJP_V2_2}
                />

                <Route exact path={["/splash-page"]} component={Preview} />
                <Route exact path={"/proceed"} component={Proceed} />
                <Route exact path={"/unqualified"} component={Unqualified} />
                <Route exact path={"/signature"} component={Signature} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={"/thankyou"} component={Thankyou} />
                <Route
                  exact
                  path={"/buds-redirect-page"}
                  component={BudsRedirectPage}
                />
                <Route exact path={["/id-upload"]} component={IdUpload} />
                <Route exact path={["/preview"]} component={PreviewLoa} />
                <Route exact path={["/404"]} component={NotFound} />
                <Route exact path={["/ADV_1"]} component={ADV_1} />
                <Route
                  exact
                  path={["/ADV1/contact"]}
                  component={ADV1_Contact}
                />
                <Route
                  exact
                  path={"/proceed-open-banking"}
                  component={OpenBankingProceed}
                />
                <Route
                  exact
                  path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]}
                  component={PdfView}
                />
                <Route
                  exact
                  path={["/google", "/testing"]}
                  component={AdtopiaLoadDynamic}
                />
              </BrowserRouter>
            </Suspense>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
