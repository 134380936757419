import React, { useState } from "react";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const BasicQuestionnaireOne = (props) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "1") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      props.slideChange(e);
    }
    if (e.target.value === "2") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      props.slideChange(e);
    }
  };

  const Radio = props.gtm_question.answers.map((value,index)=> {
    return (
      <RadioButton
        id={value.id}
        key={index}
        value={value.value}
        name={props.buttonName}
        className="radio"
        labelName={value.label}
        labelClassName={`radio ${checkedQuestionOne}`}
        onClick={nextSlide}
        validation={props.validation()}
        gtm_question ={props.gtm_question.gtmQuestion}
        gtm_trigger={props.gtm_question.gtm_trigger}
        gtm_answer={value.gtmAnswer}
        clickevent={props.gtm_question.clickevent}
      />
    )
  });

  return (
    <>
      <div id="slide-1" className={` slidetop_adjust ${props.className}`}>
      <ProgressBar value="0"/>
    
        <h4 className="text-center">
          {props.gtm_question.question}
        </h4>
        <div className="radio_buttons">
          {Radio}
          {/* <RadioButton
            value="1"
            name="question_1"
            className="radio"
            labelName="Yes"
            labelClassName={`radio ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="2"
            name="question_1"
            className="radio"
            labelName="No"
            labelClassName={`radio ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
            gtm_question = 'questinnare1'
          /> */}
        </div>
        <p className="text-center">
          <span className="not_sure" id="not-sure">
            <u>Not Sure</u>
          </span>
        </p>
      </div>
    </>
  );
};
export default BasicQuestionnaireOne;
